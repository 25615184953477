<div class="container-fluid" style="min-height: -webkit-fill-available; overflow-y: auto;">
  <app-navbar></app-navbar>
  <div class="row">
    <div class="col-md-12">
      <h2>{{ project.name }}</h2>
      <p>{{ project.description }}</p>
      <h4>Technologies Used</h4>
      <ul>
        <li *ngFor="let tech of project.technologies">{{ tech }}</li>
      </ul>
      <h4>Images</h4>
      <div class="row">
        <div class="col-md-4" *ngFor="let image of project.images">
          <img [src]="image" class="img-fluid" alt="Project Image">
        </div>
      </div>
      <h4>Videos</h4>
      <div class="row">
        <div class="col-md-6" *ngFor="let video of project.videos">
          <video controls [src]="video" class="img-fluid"></video>
        </div>
      </div>
    </div>
  </div>
</div>