<div class="container-fluid" style="min-height: -webkit-fill-available; overflow-y: auto;">
    <!-- Navbar -->
    <app-navbar></app-navbar>

    <div class="row">
        <!-- Sidebar -->
        <div class="col-md-3">
            <h4 class="mb-4">Filter by Tag</h4>
            <ul class="list-group">
                <li class="list-group-item" [ngClass]="{'active': selectedTags.length === 0}"
                    (click)="filterByTag('All')">
                    All
                </li>
                <li *ngFor="let tag of tags" class="list-group-item" [ngClass]="{'active': selectedTags.includes(tag)}"
                    (click)="filterByTag(tag)">
                    {{ tag }}
                </li>
            </ul>
        </div>

        <!-- Portfolio Section -->
        <div class="col-md-9">

            <!-- Search Bar -->
            <div class="mb-4">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search Projects" [(ngModel)]="searchQuery"
                        (input)="searchProjects()">
                </div>
            </div>

            <!-- Advanced Search Options -->
            <div class="mb-4">
                <div class="input-group">
                    <select class="form-select" [(ngModel)]="sortOption" (change)="applyFilters()">
                        <option value="">Sort By</option>
                        <option value="date">Date</option>
                        <option value="name">Name</option>
                    </select>
                </div>
            </div>

            <!-- Portfolio Grid -->
            <div class="row">
                <ng-container *ngFor="let repo of filteredRepos">
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card project-card" (mouseover)="showProjectInfo(repo.name)"
                            (mouseout)="hideProjectInfo(repo.name)">
                            <img *ngIf="repo.imageUrl" [src]="repo.imageUrl" class="card-img-top" [alt]="repo.name">
                            <div class="card-body">
                                <h5 class="card-title">{{ repo.name }}</h5>
                                <p class="card-text">{{ repo.description }}</p>
                                <div class="tags">
                                    <span class="badge bg-primary text-light me-1" *ngFor="let tag of repo.topics">
                                        {{ tag }}
                                    </span>
                                </div>
                                <div class="additional-info" [ngClass]="{'d-none': !repo.showInfo}">
                                    <p>Status: {{ repo.status }}</p>
                                    <p>Key Features: {{ repo.keyFeatures }}</p>
                                </div>
                                <a [routerLink]="['/portfolio', 1]" class="btn btn-outline-primary mt-3">
                                    View Details
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <!-- Carousel for Project Images and Videos -->
            <div id="projectCarousel" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item" *ngFor="let repo of filteredRepos; let i = index"
                        [ngClass]="{'active': i === 0}">
                        <img *ngIf="repo.imageUrl" [src]="repo.imageUrl" class="d-block w-100" [alt]="repo.name">
                        <video *ngIf="repo.videoUrl" class="d-block w-100" controls>
                            <source [src]="repo.videoUrl" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#projectCarousel" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#projectCarousel" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</div>